<script>
export default {
  name: "ProductCart",
  props: {
    item: Object,
  },
  computed: {
    title: function () {
      let ending = this.item?.name.length >= 50 ? "..." : "";

      return `${this.item?.name.substring(0, 50)}${ending}`;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body d-flex align-items-center justify-content-between">
      <p class="card-text">
        {{ title }}
      </p>
      <div class="button">
        <router-link
          :to="'/training/' + item.id"
          class="btn btn__primary"
        >
          Lihat
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <g id="feather-arrow-right" transform="translate(-6 -6)">
              <path
                id="Union_1"
                data-name="Union 1"
                d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z"
                transform="translate(6 6)"
                fill="#223975"
              />
            </g>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid $blue;
  margin-bottom: 1rem;
  height: auto;
  transition: all ease-in-out 0.3s;
  .card-body {
    .card-text {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 11pt;
      padding: 0 10px;
      color: $blue;
      text-align: center;
      transition: all ease-in-out 0.3s;
    }
    @media (max-width: 767.98px) {
      flex-direction: column;
    }
  }

  &:hover {
    background: $blue;
    .card-body {
      .card-text {
        color: white;
      }
    }
  }
}
</style>