<template>
  <div class="training_detail">
    <div class="content py-5">
      <img src="../assets/img/icons/circle-two2.svg" alt="" class="circle" />
      <img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v" />
      <img src="../assets/img/icons/rect-layer2.svg" alt="" class="rect" />
      <img
        src="../assets/img/icons/dot-vertical-22.svg"
        alt=""
        class="dot_v2"
      />
      <div class="text mb-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="hero">
                  <div class="hero_title">
                    <h2 class="title mb-5">
                      {{ product.name }}
                    </h2>
                  </div>
                </div>

                <div class="desc" v-html="product.desc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button text-center">
        <router-link :to="{ name: 'training' }" class="btn btn__primary">
          <svg
            id="feather-arrow-left"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            class="me-2"
            viewBox="0 0 10 10"
          >
            <path
              id="Union_1"
              data-name="Union 1"
              d="M4.558.183a.626.626,0,0,0,0,.884L7.867,4.375H.625a.625.625,0,0,0,0,1.25H7.866L4.558,8.933a.625.625,0,0,0,.884.884L9.817,5.442l0,0,0,0,.008-.008v0a.624.624,0,0,0,.161-.5V4.907l0-.01v0l0-.012v0l0-.013h0c0-.01,0-.019-.007-.029h0l0-.014h0l0-.013v0l0-.012v0l0-.01,0-.005,0-.008,0-.009,0,0-.005-.012v0a.616.616,0,0,0-.078-.128l-.005-.006,0-.005L9.852,4.6l0,0-.008-.009,0,0-.009-.01h0l-.011-.011L5.442.183a.626.626,0,0,0-.884,0Z"
              transform="translate(10 10) rotate(180)"
            />
          </svg>
          Kembali
        </router-link>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Footer from "@/components/Footer.vue";

import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "TrainingDetail",
  components: {
    Footer,
  },
  setup() {
    const product = ref([]);
    const route = useRoute();

    onMounted(() => {
      axios
        .get(`getType/${route.params.id}`)
        .then((result) => {
          console.log("Produk Detail: ", result.data.type);
          product.value = result.data.type;
        })
        .catch((err) => {
          console.log(err.response);
        });
    });

    return {
      product,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
.training_detail {
  .content {
    position: relative;
    // margin: 40px 0;
    background: $blue;
    .circle {
      position: absolute;
      top: 0;
      left: 0;
      width: 400px;
      height: auto;
      opacity: 0.06;
    }
    .dot_v {
      position: absolute;
      top: 0;
      left: 20px;
      width: 90px;
      height: auto;
      opacity: 0.06;
    }
    .dot_v2 {
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 90px;
      height: auto;
      opacity: 0.06;
    }
    .rect {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 420px;
      height: auto;
      opacity: 0.06;
    }
  }

  .hero {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      order: 2;
      width: 100%;
      h2.title {
        font-weight: 700;
        color: $blue;
      }
    }
    &_slider {
      order: 1;
      width: 60%;
      z-index: 1;
      img {
        width: 100%;
      }
    }
  }

  .text {
    position: relative;

    .box {
      background: white;
      padding: 40px 60px;
      // margin: 100px 0 40px;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 4;
      .desc {
        text-align: center;
        color: $darkGrey;
        font-family: "Poppins", sans-serif;
        text-align: justify;
      }
    }
  }
}

.button {
  z-index: 2;
}

@media (max-width: 767.98px) {
  .hero {
    flex-wrap: wrap;
    width: 100%;
    &_title {
      order: 2;
      width: 100%;
      padding-right: 0;
      h2.title {
        text-align: center;
        font-size: 16pt;
      }
      .btn__primary {
        display: block;
        margin: 30px auto 0;
      }
    }
    &_slider {
      order: 1;
      width: 100%;
    }
  }
  .content {
    padding: 40px 0 0;
    .head {
      .circle {
        width: 200px;
      }
      .dot_v {
        width: 50px;
      }
      .dot_v2 {
        width: 50px;
      }
      .rect {
        width: 220px;
      }
    }
    .text {
      .box {
        padding: 30px 20px;
      }
    }
  }
}
</style>