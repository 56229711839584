<template>
    <div class="news">
        <div class="head">
            <img src="../assets/img/icons/circle-two2.svg" alt="" class="circle">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v">
			<img src="../assets/img/icons/rect-layer2.svg" alt="" class="rect">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v2">
            <div class="container">
                <div class="inner">
                    <h1 class="title">
                        Semua Berita
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="container">
                <div class="row">
                    <div v-for="(item, index) in latesNews" :key="index">
                    <div class="col-12" v-show="index == 0">
                        <div class="box">
                            <figure class="news-img">
                                <img :src="imageUrl+'berita/'+item.image" alt="">
                            </figure>
                            <div class="inner_box">
                                <div class="item">
                                    <router-link to="/news/detail" class="title" v-if="item.title.length>=60">
                                        {{ item.title.substring(0,60)+'...' }}
                                    </router-link>
                                    <router-link to="/news/detail" class="title" v-else>
                                        {{ item.title }}
                                    </router-link>
                                    <p class="time">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-clock" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                        </svg>
                                        {{dateTime(item.created_at)}}
                                    </p>
                                </div>
                                <div class="button">
                                    <router-link :to="'/news/detail/'+item.id" class="btn btn__primary">
                                        Lihat
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                            <g id="feather-arrow-right" transform="translate(-6 -6)">
                                                <path id="Union_1" data-name="Union 1" d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z" transform="translate(6 6)" fill="#223975"/>
                                            </g>
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-4 col-6" v-for="(item, index) in news" :key="index">
                        <div class="card">
                            <figure class="card-img-top">
                                <img :src="imageUrl+'berita/'+item.image" alt="...">
                            </figure>
                            <div class="card-body">
                                <p class="card-text" v-if="item.title.length>=34">{{item.title.substring(0,34)+'...'}}</p>
                                <p class="card-text" v-else>{{item.title}}</p>
                                <small class="time">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                    </svg>
                                    {{dateTime(item.created_at)}}
                                </small>
                                <div class="card-footer">
                                    <router-link :to="'/news/detail/'+item.id" class="btn btn__primary">
                                        Lihat
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                            <g id="feather-arrow-right" transform="translate(-6 -6)">
                                                <path id="Union_1" data-name="Union 1" d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z" transform="translate(6 6)" fill="#223975"/>
                                            </g>
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'

import moment from 'moment'

export default {
    name: 'News',
    components: {
        Footer,
    },
    setup(){
        const latesNews = ref([])
        const news = ref([])

        onMounted(() => {
            
            axios.get('getNews')
			.then((result) => {
                console.log('Lates News : ', result.data)
                latesNews.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
        })
        onMounted(() => {
            
            axios.get('getNews')
			.then((result) => {
                console.log('Page News : ', result.data)
                news.value = result.data.splice(1)
			}).catch((err) => {
				console.log(err.response)
			})
        })

        function dateTime(value) {
			return moment(value).format("DD MMMM YYYY hh:mm:ss A");
		}

        return{
            news,
            latesNews,
            dateTime,
        }
    }
}
</script>

<style lang="scss" scoped>
.news{
    .head{
        background: $blue;
		position: relative;
		height: 300px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 400px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.dot_v{
			position: absolute;
			top: 20px;
			left: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.dot_v2{
			position: absolute;
			bottom: 20px;
			right: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.rect{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 420px;
			height: auto;
			z-index: 1;
            opacity: 0.06;
		}
        .inner{
            .subtitle{
                color: white;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 14pt;
            }
            .title{
                color: white;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 50px;
            }
        }
    }
    .content{
        .box{
            position: relative;
            margin: -150px 0 40px;
            box-shadow: 0 5px 30px rgba($color: #000000, $alpha: 0.1);
            z-index: 4;
            .news-img{
                width: 100%;
                height: 400px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .inner_box{
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba($color: $blue, $alpha: 0.35);
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                width: 100%;
                .item{
                    width: 80%;
                    .title{
                        color: white;
                        font-weight: 600;
                        // margin-right: 30px;
                        text-transform: capitalize;
                        text-decoration: none;
                        font-size: 16pt;
                    }
                    .time{
						font-size: 10pt;
						color: white;
						svg{
							width: 12px;
							height: 12px;
						}
					}
                }
                .button{
                    width: 20%;
                    .btn__primary{
						font-size: 10pt;
						float: right;
						padding: 10px 20px 10px 40px;
                        background: white;
						svg{
							margin-left: 20px;
						}
					}
                }
            }
        }
        .card{
			border: none;
			border-radius: 0;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
            margin-bottom: 40px;
            height: 300px;
			.card-img-top{
				width: 100%;
				height: 180px;
				margin-bottom: 0;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.card-text{
				font-weight: 700;
				margin-bottom: 0;
                color: $darkGrey;
			}
			.time{
				font-size: 8pt;
				color: $lightGrey;
				svg{
					width: 12px;
					height: 12px;
				}
			}
            .card-footer{
                background: none;
                border: none;
                .btn__primary{
                    font-size: 10pt;
                    float: right;
                    padding: 10px 20px 10px 40px;
                    svg{
                        margin-left: 20px;
                    }
                }
            }
		}
    }
    @media (max-width: 767.98px){
        .head{
            .circle{
				width: 200px;
			}
			.dot_v{
				width: 50px;
			}
			.dot_v2{
				width: 50px;
			}
			.rect{
				width: 220px;
			}
        }
        .content{
            .box{
                .inner_box{
                    display: block;
                    .item{
                        width: 100%;
                        .title{
                            font-size: 12pt !important;
                        }
                    }
                    .button{
                        width: 100%;
                    }
                }
            }
            .card{
                height: 240px;
                .card-img-top{
                    height: 120px;
                }
                .time{
                    font-size: 6pt;
                    color: $lightGrey;
                    display: flex;
                    align-items: flex-start;
                    svg{
                        margin-right: 3px;
                        margin-bottom: 10px;
                        width: 10px;
                        height: 10px;
                    }
                }
                .card-text{
                    font-size: 9pt;
                }
                .card-footer{
                    .btn__primary{
                        font-size: 8pt;
                        float: right;
                        padding: 10px;
                        svg{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>