import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Training from '../views/Training.vue'
import TrainingDetail from '../views/TrainingDetail.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import Client from '../views/Client.vue'
// import ClientDetail from '../views/ClientDetail.vue'
import Contact from '../views/Contact.vue'
import { h } from 'vue'
import { RouterView } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/training',
    // name: 'training',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'training',
        component: Training,
      },
      {
        path: ':id',
        name: 'training-detail',
        component: TrainingDetail
      },
    ]
  },

  {
    path: '/news',
    // name: 'news',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'news',
        component: News
      },
      {
        path: 'detail/:id',
        name: 'news-detail',
        component: NewsDetail
      },
    ]
  },
  {
    path: '/organization',
    name: 'client',
    component: Client
  },
  // {
  //   path: '/organization/detail/:id',
  //   name: 'client-detail',
  //   component: ClientDetail
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
