<template>
    <div class="contact">
        <div class="head">
            <img src="../assets/img/icons/circle-two.svg" alt="" class="circle">
			<img src="../assets/img/icons/dot-vertical-2.svg" alt="" class="dot_v">
			<img src="../assets/img/icons/rect-layer.svg" alt="" class="rect">
			<img src="../assets/img/icons/dot-vertical-2.svg" alt="" class="dot_v2">
            <div class="container">
                <div class="inner">
                    <h1 class="title">
                        Ada Pertanyaan Untuk Kami?
                    </h1>
                </div>
            </div>
        </div>

        <div class="contact_us">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <h3 class="title">
                            Hubungi Kami
                        </h3>
                        <div class="line">
                            <span class="grey"></span>
                            <span class="light_blue"></span>
                            <span class="blue"></span>
                        </div>

                        <div v-if="sukses" class="alert alert-success mt-3" role="alert">
							<strong>Berhasil!</strong> Pesan anda telah terkirim.
						</div>
                        <form @submit.prevent="store()">
                            <div class="mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Nama" v-model="contact.nama" required>
                                <div v-if="v$.nama.$error" class="mt-2 text-start text-danger error__text">
                                    {{ v$.nama.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <input type="email" class="form-control" id="email" placeholder="Email" v-model="contact.email" required pattern="[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?">
                                <div v-if="v$.email.$error" class="mt-2 text-start text-danger error__text">
                                    {{ v$.email.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control" id="telp" placeholder="No. Telepon" v-model="contact.phone_number" required>
                                <div v-if="v$.phone_number.$error" class="mt-2 text-start text-danger error__text">
                                    {{ v$.phone_number.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control" id="subject" placeholder="Subjek" v-model="contact.subject" required>
                                <div v-if="v$.subject.$error" class="mt-2 text-start text-danger error__text">
                                    {{ v$.subject.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <textarea class="form-control" id="pesan" rows="5" placeholder="Pesan" v-model="contact.message" required></textarea>
                                <div v-if="v$.message.$error" class="mt-2 text-start text-danger error__text">
                                    {{ v$.message.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <button type="submit" class="btn btn__primary d-flex align-items-center justify-content-center" :disabled="loading">
                                    <div v-if="loading" class="spinner-border text__primary me-2" :disabled="loading"></div>
                                    <span v-if="!loading">Kirim</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-6">
                        <div class="maps">
                            <iframe 
                                frameborder="0" 
                                scrolling="no"
                                marginheight="0" 
                                marginwidth="0" 
                                :src="'https://maps.google.com/maps?q='+info.latitude+','+info.longitude+'&hl=en&z=14&amp;output=embed'"
                            >
                            </iframe>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="info">
                            <div class="item">
                                <div class="icon">
                                    <img src="../assets/img/icons/map-pin.svg" alt="">
                                </div>
                                <div class="text">
                                    <h6>Lokasi :</h6>
                                    <p>{{info.address}}</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="icon">
                                    <img src="../assets/img/icons/email-icon.svg" alt="">
                                </div>
                                <div class="text">
                                    <h6>Email :</h6>
                                    <p><a :href="'mailto:'+info.email1">{{info.email1}}</a></p>
                                    <p><a :href="'mailto:'+info.email2">{{info.email2}}</a></p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="icon">
                                    <img src="../assets/img/icons/phone-icon.svg" alt="">
                                </div>
                                <div class="text">
                                    <h6>Telepon :</h6>
                                    <p>{{info.no_telp1}}</p>
                                    <p>{{info.no_telp2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { reactive, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
    name: 'Contact',
    components: {
        Footer,
    },
    setup(){
        const contact = reactive ({
			email: '',
			nama: '',
			subject: '',
			phone_number: '',
			message: '',
		});

        const router = useRouter();
        const sukses = ref(false);
		const loading = ref(false);

		const validation = ref([]);
		const info = ref([]);

        onMounted(() => {
			axios.get('getSetting')
			.then((result) => {
				console.log('Contact Info :', result.data)
				info.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        const rules = computed(() => {
            return{
                email: { required, email },
                nama: { required },
                subject: { required },
                phone_number: { required },
                message: { required }
            }
        })

        const v$ = useValidate(rules, contact)

        function store() {
			axios.post(
				'postContact',
				contact
			)
			.then(() => {
				loading.value = true
				setTimeout(() => {
					loading.value = false
				}, 1000)

				this.contact.email = ''
				this.contact.nama = ''
				this.contact.subject = ''
				this.contact.phone_number = ''
				this.contact.message = ''

				sukses.value = true
				setTimeout(() => {
					sukses.value = false
				}, 4000)

				// router.push({
				// 	path: '/contact'
				// });
				// alert('Pesan Anda Berhasil Terkirim'),
				// location.reload()
			}).catch((err) => {
                console.log(err.response)
				validation.value = err.response
			});
        }
        return{
            contact,
            router,
            info,
            sukses,
            validation,
            loading,
            store,
            v$,
            rules,
        }
    }
}
</script>

<style lang="scss" scoped>
.contact{
    .head{
        background: rgb(252, 252, 252);
		position: relative;
		height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 360px;
			height: auto;
			z-index: 2;
		}
		.dot_v{
			position: absolute;
			top: 20px;
			left: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
		}
		.dot_v2{
			position: absolute;
			bottom: 20px;
			right: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
		}
		.rect{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 380px;
			height: auto;
			z-index: 1;
		}
        .inner{
            .subtitle{
                color: white;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 14pt;
            }
            .title{
                color: $blue;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                // margin-top: 50px;
            }
        }
    }

    .contact_us{
        padding: 40px 0;
        .title{
            color: $darkGrey;
            font-weight: 700;
        }
        .line{
            position: relative;
            .grey{
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: 120px;
                background: #EBEBEB;
                z-index: 1;
            }
            .light_blue{
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: 90px;
                background: $secondary;
                z-index: 2;
            }
            .blue{
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: 60px;
                background: $blue;
                z-index: 2;
            }
        }
        form{
            margin-top: 40px;
            padding: 0 40px 0 0;
            input, textarea{
                border-radius: 0;
                padding: 10px;
                color: $darkGrey;
                font-family: 'Poppins', sans-serif;
                box-shadow: none;
            }
            .btn__primary{
                width: 100%;
                text-transform: uppercase;
                font-weight: 700;
                font-family: 'Poppins', sans-serif;
                padding: 15px;
            }
        }
        .maps{
            iframe{
                width: 100%;
                height: 540px;
            }
        }
        .info{
            padding: 50px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item{
                display: flex;
                align-items: center;
                justify-content: center;
                .icon{
                    margin-right: 20px;
                    img{
                        width: auto;
                        height: 60px;
                    }
                }
                .text{
                    h6{
                        color: $blue;
                        font-weight: 700;
                    }
                    p{
                        color: $grey;
                        font-weight: 600;
                        margin-bottom: 0;
                        a{
                            color: $grey;
                            text-decoration: none;
                            &:hover{
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767.98px){
        .head{
            .circle{
				width: 200px;
			}
			.dot_v{
				width: 50px;
			}
			.dot_v2{
				width: 50px;
			}
			.rect{
				width: 220px;
			}
        }
        .contact_us{
            form{
                padding: 0;
            }
            .maps{
                margin-top: 40px;
                iframe{
                    width: 100%;
                    height: 340px;
                }
            }
            .info{
                flex-wrap: wrap;
                .item{
                    margin: 10px 10px 15px;
                }
            }
        }
    }
}
</style>